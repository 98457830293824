import styled from "@emotion/styled";
import Link from "next/link";
import React, { useMemo } from "react";
import { BsFillGearFill } from "react-icons/bs";
import { FaArrowLeft, FaInfoCircle, FaPowerOff } from "react-icons/fa";

import { user_type_enum_enum } from "~src/__generated__/graphql/types";
import { useRestoreFromDemo } from "~src/admin/demos/hooks/useRestoreFromDemo";
import { TwoPartCard } from "~src/designSystem/atoms/TwoPartCard";
import { Flex } from "~src/designSystem/layout/Flex";
import { t } from "~src/designSystem/theme";
import { useVendorImpersonateStore } from "~src/shared/auth/store";
import { useUser } from "~src/shared/auth/useUser";

interface IProps {
  user: ReturnType<typeof useUser>;
  logout: () => void;
}

export const ProfileButtonContent: React.FC<IProps> = ({ user, logout }: IProps) => {
  const settingsLink = useMemo(() => {
    switch (user.userType) {
      case user_type_enum_enum.vendor_admin:
        return "/settings";
      case user_type_enum_enum.investor:
        return "/investor/settings";
      default:
        return "/admin/settings";
    }
  }, [user]);

  const impersonateVendorPublicID = useVendorImpersonateStore(
    (state) => state.impersonateVendorPublicID,
  );
  const setImpersonateVendorPublicID = useVendorImpersonateStore(
    (state) => state.setImpersonateVendorPublicID,
  );

  const { hasAdminBackupSession, restoreAdminBackupSession } = useRestoreFromDemo();

  return (
    <Wrapper>
      <Who>
        <Name>{user.name}</Name>
        <ClientID>{user.vendor.clientID}</ClientID>
      </Who>
      <ProfileMenu>
        <Link href={settingsLink} passHref>
          <ProfileMenuLink id="profile_dropdown_settings_link">
            <BsFillGearFill />
            <Flex direction="column">Settings</Flex>
          </ProfileMenuLink>
        </Link>
        {user.userType === user_type_enum_enum.vendor_admin && (
          <Link href="/changelog" passHref>
            <ProfileMenuLink id="profile_dropdown_changelog_link">
              <FaInfoCircle />
              <Flex direction="column">Changelog</Flex>
            </ProfileMenuLink>
          </Link>
        )}
        {hasAdminBackupSession && (
          <ProfileMenuLink onClick={restoreAdminBackupSession}>
            <FaArrowLeft />
            <Flex direction="column">Return to MC</Flex>
          </ProfileMenuLink>
        )}
        {/* If we are impersonating a vendor, replace logout with go back to admin. */}
        {impersonateVendorPublicID === null ? (
          <ProfileMenuLink id="profile_dropdown_logout_link" as="button" onClick={logout}>
            <FaPowerOff />
            <Flex direction="column">Logout</Flex>
          </ProfileMenuLink>
        ) : (
          <ProfileMenuLink
            as="button"
            onClick={() => {
              setImpersonateVendorPublicID(null);
              window.location.assign("/admin");
            }}
          >
            <FaArrowLeft />
            <Flex direction="column">Stop impersonating</Flex>
          </ProfileMenuLink>
        )}
      </ProfileMenu>
    </Wrapper>
  );
};

const Wrapper = styled(TwoPartCard)`
  background-color: ${(props) => props.theme.components.NavBar.ProfileMenu.background};
  border-radius: ${t.radii[2].toString()};
  min-width: 160px;
`;

const Who = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${t.c.spacing("3", "5")};

  font-size: 12px;
  line-height: 15px;
  color: ${(props) => props.theme.components.ProfileMenu.Who.text};
`;

const Name = styled.span`
  font-weight: 600;
`;

const ClientID = styled.span``;

const ProfileMenu = styled.div`
  padding: ${t.c.spacing("2", "0")};

  display: grid;
  grid-template-columns: 100%;
`;

const ProfileMenuLink = styled.a`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  color: ${(props) => props.theme.components.ProfileMenu.Link.text};
  width: 100%;
  cursor: pointer;
  &:hover {
    /* TODO: Why do we have a's with a blue hover by default? */
    color: ${(props) => props.theme.components.ProfileMenu.Link.text} !important;
    background: ${(props) => props.theme.components.ProfileMenu.Link.background.hover};
  }

  padding: 6px 16px;
  svg {
    color: ${(props) => props.theme.components.ProfileMenu.Link.icon};
    margin-right: 8px;
  }
`;
