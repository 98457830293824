import styled from "@emotion/styled";
import React, { useMemo } from "react";

import { BlueprintIcon } from "~src/designSystem/deprecated/BlueprintIcon";
import { PleaseGoUseFlexInstead } from "~src/designSystem/deprecated/Flexbox";
import { InvalidRequirementError } from "~src/shared/dataSourcesRequirements/errors";
import { IRequirement } from "~src/shared/dataSourcesRequirements/hooks/useFetchDataSourceRequirements";

type INotification = {
  content: string;
  callToAction: string;
};

type IProps = {
  requirement: IRequirement;
  onClick: () => void;
};

export const NotificationItem: React.FC<IProps> = (props) => {
  const { content, callToAction } = useMemo(
    () => makeNotification(props.requirement),
    [props.requirement],
  );

  return (
    <Wrapper row paddingX="12px" paddingY="8px" gap="8px" onClick={props.onClick}>
      <IconWrapper>
        <BlueprintIcon icon="error" />
      </IconWrapper>
      <div>
        <Content>{content}</Content>
        <CallToAction>{callToAction}</CallToAction>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled(PleaseGoUseFlexInstead)`
  cursor: pointer;
  transition: ${(props) => props.theme.transition};
  &:hover {
    background-color: ${(props) => props.theme.components.Notifications.NotificationItem.hoverBg};
  }
`;

const IconWrapper = styled.div`
  color: ${(props) => props.theme.components.Notifications.NotificationItem.icon};
  margin-top: 2px;
`;

const Content = styled.div`
  ${(props) => props.theme.textStyles.Regular.Body200};
  color: ${(props) => props.theme.components.Notifications.NotificationItem.content};
`;

const CallToAction = styled.div`
  ${(props) => props.theme.textStyles.Regular.Body200};
  color: ${(props) => props.theme.components.Notifications.NotificationItem.cta};
`;

export const makeNotification = (req: IRequirement): INotification => {
  if (req.type === "initial" && req.category === "accounting") {
    return {
      content: "Sync your accounting platform to get started.",
      callToAction: "Click to connect your accounting platform.",
    };
  }

  if (req.type === "additional" && req.category === "accounting") {
    return {
      content: "An additional accounting data source has been requested.",
      callToAction: "Click to connect your accounting platform.",
    };
  }

  if (req.type === "relink" && req.category === "accounting") {
    return {
      content: "Reconnect your accounting platform.",
      callToAction: `Your ${req.sourceName} account has been disconnected. Click to reconnect.`,
    };
  }

  if (req.type === "initial" && req.category === "bank") {
    return {
      content: "Sync your bank account to get started.",
      callToAction: "Click to connect your bank account.",
    };
  }
  if (req.type === "additional" && req.category === "bank") {
    return {
      content: "An additional bank data source has been requested.",
      callToAction: "Click to connect your bank account.",
    };
  }
  if (req.type === "relink" && req.category === "bank") {
    return {
      content: "Reconnect your bank account.",
      callToAction: `Your ${req.sourceName} account has been disconnected. Click to reconnect.`,
    };
  }

  if (req.type === "initial" && req.category === "revenue") {
    return {
      content: "Sync your revenue streams to get started.",
      callToAction: "Click to connect your billing manager account.",
    };
  }
  if (req.type === "additional" && req.category === "revenue") {
    return {
      content: "An additional revenue stream data source has been requested.",
      callToAction: "Click to connect your billing manager account.",
    };
  }
  if (req.type === "relink" && req.category === "revenue") {
    return {
      content: "Reconnect your billing manager account.",
      callToAction: `Your ${req.sourceName} account has been disconnected. Click to reconnect.`,
    };
  }

  throw new InvalidRequirementError("Requirement does not map to a notification.");
};
