import { useEffect } from "react";

import {
  ISegmentTrackEvent,
  ISegmentTrackPage,
  useAnalytics,
} from "~src/shared/thirdParties/segment";
import { IConnectDataSourceFlowSource, IUploadedVendorDataSourceModel } from "~src/shared/types";

export const useDataSourceCSVUploadTracking = (
  source?: IConnectDataSourceFlowSource,
): ((
  billingSourceToEventMap: { [key in IConnectDataSourceFlowSource]: ISegmentTrackEvent | null },
  properties: {
    model: IUploadedVendorDataSourceModel;
    label: string;
  },
) => void) => {
  const { trackEvent, trackPage } = useAnalytics();

  useEffect(() => {
    const impressionEvent =
      source != null ? CONNECT_BILLING_SOURCE_TO_CSV_UPLOADER_MODAL_IMPRESSION_EVENT[source] : null;
    if (impressionEvent != null) {
      trackPage(impressionEvent);
    }
  }, [source, trackPage]);

  return (billingSourceToEventMap, properties) => {
    const event = source != null ? billingSourceToEventMap[source] : null;
    if (event != null) {
      trackEvent(event, properties);
    }
  };
};

export const CONNECT_BILLING_SOURCE_TO_CSV_UPLOADER_MODAL_IMPRESSION_EVENT: {
  [key in IConnectDataSourceFlowSource]: ISegmentTrackPage | null;
} = {
  "notifications-badge": null,
  "signup-flow": null,
  "cash-performance-flow": null,
  "trade-orderbox-add-payout-flow": null,
  "being-reviewed-add-more-data": null,
  "settings-flow":
    ISegmentTrackPage.SettingsBillingManagerPageBillingManagerModalCSVUploaderModalImpression,
  "suggestion-flow":
    ISegmentTrackPage.SuggestionBillingManagerPageBillingManagerModalCSVUploaderModalImpression,
  "trade-required-action-flow":
    ISegmentTrackPage.TradeRequiredActionBillingManagerPageBillingManagerModalCSVUploaderModalImpression,
};

export const CONNECT_BILLING_SOURCE_TO_CSV_UPLOADER_MODAL_BACK_BUTTON_CLICKED_EVENT: {
  [key in IConnectDataSourceFlowSource]: ISegmentTrackEvent | null;
} = {
  "notifications-badge": null,
  "signup-flow": null,
  "cash-performance-flow": null,
  "trade-orderbox-add-payout-flow": null,
  "being-reviewed-add-more-data": null,
  "settings-flow":
    ISegmentTrackEvent.SettingsBillingManagerPageBillingManagerModalCSVUploaderModalBackButtonClicked,
  "suggestion-flow":
    ISegmentTrackEvent.SuggestionBillingManagerPageBillingManagerModalCSVUploaderModalBackButtonClicked,
  "trade-required-action-flow":
    ISegmentTrackEvent.TradeRequiredActionBillingManagerPageBillingManagerModalCSVUploaderModalBackButtonClicked,
};

export const CONNECT_BILLING_SOURCE_TO_CSV_UPLOADER_MODAL_CLOSED_OUT_EVENT: {
  [key in IConnectDataSourceFlowSource]: ISegmentTrackEvent | null;
} = {
  "notifications-badge": null,
  "signup-flow": null,
  "cash-performance-flow": null,
  "trade-orderbox-add-payout-flow": null,
  "being-reviewed-add-more-data": null,
  "settings-flow":
    ISegmentTrackEvent.SettingsBillingManagerPageBillingManagerModalCSVUploaderModalClosedOut,
  "suggestion-flow":
    ISegmentTrackEvent.SuggestionBillingManagerPageBillingManagerModalCSVUploaderModalClosedOut,
  "trade-required-action-flow":
    ISegmentTrackEvent.TradeRequiredActionBillingManagerPageBillingManagerModalCSVUploaderModalClosedOut,
};

export const CONNECT_BILLING_SOURCE_TO_CSV_UPLOADER_MODAL_FILE_UPLOADED_EVENT: {
  [key in IConnectDataSourceFlowSource]: ISegmentTrackEvent | null;
} = {
  "notifications-badge": null,
  "signup-flow": null,
  "cash-performance-flow": null,
  "trade-orderbox-add-payout-flow": null,
  "being-reviewed-add-more-data": null,
  "settings-flow":
    ISegmentTrackEvent.SettingsBillingManagerPageBillingManagerModalCSVUploaderModalFileUploaded,
  "suggestion-flow":
    ISegmentTrackEvent.SuggestionBillingManagerPageBillingManagerModalCSVUploaderModalFileUploaded,
  "trade-required-action-flow":
    ISegmentTrackEvent.TradeRequiredActionBillingManagerPageBillingManagerModalCSVUploaderModalFileUploaded,
};

export const CONNECT_BILLING_SOURCE_TO_CSV_UPLOADER_MODAL_UPLOAD_BUTTON_CLICKED_EVENT: {
  [key in IConnectDataSourceFlowSource]: ISegmentTrackEvent | null;
} = {
  "notifications-badge": null,
  "signup-flow": null,
  "cash-performance-flow": null,
  "trade-orderbox-add-payout-flow": null,
  "being-reviewed-add-more-data": null,
  "settings-flow":
    ISegmentTrackEvent.SettingsBillingManagerPageBillingManagerModalCSVUploaderModalUploadButtonClick,
  "suggestion-flow":
    ISegmentTrackEvent.SuggestionBillingManagerPageBillingManagerModalCSVUploaderModalUploadButtonClick,
  "trade-required-action-flow":
    ISegmentTrackEvent.TradeRequiredActionBillingManagerPageBillingManagerModalCSVUploaderModalUploadButtonClick,
};

export const CONNECT_BILLING_SOURCE_TO_CSV_UPLOADER_MODAL_DOCS_LINK_CLICKED_EVENT: {
  [key in IConnectDataSourceFlowSource]: ISegmentTrackEvent | null;
} = {
  "notifications-badge": null,
  "signup-flow": null,
  "cash-performance-flow": null,
  "trade-orderbox-add-payout-flow": null,
  "being-reviewed-add-more-data": null,
  "settings-flow":
    ISegmentTrackEvent.SettingsBillingManagerPageBillingManagerModalCSVUploaderModalDocsLinkClick,
  "suggestion-flow":
    ISegmentTrackEvent.SuggestionBillingManagerPageBillingManagerModalCSVUploaderModalDocsLinkClick,
  "trade-required-action-flow":
    ISegmentTrackEvent.TradeRequiredActionBillingManagerPageBillingManagerModalCSVUploaderModalDocsLinkClick,
};

export const CONNECT_BILLING_SOURCE_TO_CSV_UPLOADER_MODAL_TEMPLATE_LINK_CLICKED_EVENT: {
  [key in IConnectDataSourceFlowSource]: ISegmentTrackEvent | null;
} = {
  "notifications-badge": null,
  "signup-flow": null,
  "cash-performance-flow": null,
  "trade-orderbox-add-payout-flow": null,
  "being-reviewed-add-more-data": null,
  "settings-flow":
    ISegmentTrackEvent.SettingsBillingManagerPageBillingManagerModalCSVUploaderModalTemplateLinkClick,
  "suggestion-flow":
    ISegmentTrackEvent.SuggestionBillingManagerPageBillingManagerModalCSVUploaderModalTemplateLinkClick,
  "trade-required-action-flow":
    ISegmentTrackEvent.TradeRequiredActionBillingManagerPageBillingManagerModalCSVUploaderModalTemplateLinkClick,
};
