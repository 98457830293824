import { css, Theme, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import clsx from "clsx";
import { motion } from "framer-motion";
import React from "react";

import {
  IBoxStyles,
  IGridStyles,
  withBoxStyles,
  withGridStyles,
} from "~src/shared/helpers/sharedComponentProps";

import { LoadingDots } from "../../loading/LoadingDots";
import { PleaseGoUseFlexInstead } from "../Flexbox";

export type IButtonProps = {
  /** primary styles */
  primary?: boolean;

  /** secondary styles */
  secondary?: boolean;
  /** no background */
  transparent?: boolean;

  /** replace inner contents with loading spinner */
  isLoading?: boolean;
  disabled?: boolean;
  /** width: 100% */
  fullWidth?: boolean;
  size?: "large" | "medium";
  /** additional css class names */
  className?: string;
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  id?: string;
} & Pick<
  React.ComponentProps<typeof motion.button>,
  // TODO(Benny): does hidden exist as a property?
  "type" | "hidden" | "style"
> &
  IGridStyles &
  IBoxStyles;

/**
 * @deprecated please use Button.aria
 *
 * Atomic button component. Should be pure and only define styles.
 * All buttons should extend this component.
 *
 * With the exception of states such as `isLoading` which enables the loading state.
 */
export const OldButtonTryToAvoid = styled((props: IButtonProps) => {
  const { disabled, secondary, isLoading } = props;

  const theme = useTheme();

  return (
    <StyledButton {...props} className={clsx("old-button", props.className)}>
      <PleaseGoUseFlexInstead
        row
        alignItems="center"
        style={{ visibility: isLoading === true ? "hidden" : "visible" }}
      >
        {props.children}
      </PleaseGoUseFlexInstead>
      {isLoading === true && (
        <LoadingContainer>
          <LoadingDots
            size={22}
            frontColor={(() => {
              if (disabled === true) return theme.components.Button.primary.text.disabled;
              if (secondary === true) return theme.components.Button.secondary.loading;
              return theme.components.Button.primary.loading;
            })()}
            backColor={theme.components.Button.primary.loading}
          />
        </LoadingContainer>
      )}
    </StyledButton>
  );
})``;

const LoadingContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const secondaryStyles = (props: IButtonProps & { theme: Theme }) => css`
  color: ${props.theme.components.Button.secondary.text.default};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.07);
  background: ${props.theme.components.Button.secondary.background.default};
  &:hover {
    background: ${props.theme.components.Button.secondary.background.hover};
  }
  ${props.disabled === true &&
  css`
    color: ${props.theme.components.Button.primary.text.disabled};
    &:hover {
      background: transparent;
      color: ${props.theme.components.Button.primary.text.disabled};
      cursor: unset;
    }
  `}
`;

const StyledButton = styled(motion.button)<IButtonProps>`
  /* Required bc of antd bug https://github.com/react-component/tooltip/issues/18#issuecomment-411476678 */
  /* thanks to brandon, benny, josh, and ian 2020-08-03 offsite */
  pointer-events: ${(props) => props.disabled === true && "none"};

  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  & > span {
    position: relative;
  }

  padding: 8px 12px;

  min-width: fit-content;
  width: fit-content;
  height: fit-content;
  width: ${(props) => props.fullWidth === true && "100%"};

  font-family: inherit;
  font-weight: 500;
  font-size: 13px;
  height: 32px;
  line-height: 14px;

  border: none;
  border-radius: 4px;
  background-color: ${(props) => props.theme.components.Button.primary.background.default};
  color: ${(props) => props.theme.components.Button.primary.text.default};
  box-shadow: 0 0 0 transparent;
  transition: all 0.2s ease;

  &:hover {
    cursor: pointer;

    ${(props) =>
      props.isLoading !== true &&
      props.disabled !== true &&
      props.secondary !== true &&
      css`
        background: ${props.primary === true &&
        props.theme.components.Button.primary.background.hover};
        opacity: 0.9;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      `}

    ${(props) =>
      props.isLoading === true &&
      css`
        background: ${props.secondary === true && "transparent"} !important;
        cursor: not-allowed;
      `}
  }
  &:focus {
    outline: none;
  }

  ${(props) =>
    props.disabled === true &&
    css`
      background: ${props.theme.components.Button.primary.background.disabled};
      color: ${props.theme.components.Button.primary.text.disabled};
      &:hover {
        background: ${props.theme.components.Button.primary.background.disabled};
        color: ${props.theme.components.Button.primary.text.disabled};
        cursor: unset;
      }
    `}

  ${(props) => withGridStyles(props)}
  ${(props) => withBoxStyles(props)}
  ${(props) => props.secondary === true && secondaryStyles(props)}

  ${(props) =>
    props.size !== undefined &&
    css`
      height: ${(() => {
        switch (props.size) {
          case "large":
            return "48px";
          case "medium":
            return "36px";
          default:
            return "auto";
        }
      })()};
    `}

    ${(props) =>
    props.transparent === true &&
    css`
      background-color: transparent;
      padding: 0;
      color: ${props.theme.components.Button.primary.background.default};
      &:hover {
        background-color: transparent;
        color: ${props.theme.components.Button.primary.background.hover};
      }
    `}
`;
