import styled from "@emotion/styled";
import { MouseEventHandler } from "react";

import { Button } from "~src/designSystem/atoms/Button";
import { t } from "~src/designSystem/theme";

export interface IDataSourceLayoutButtonSectionProps {
  primaryButtonAction?: MouseEventHandler<HTMLButtonElement>;
  primaryButtonText?: string;
  isPrimaryButtonDisabled?: boolean;
  isPrimaryButtonLoading?: boolean;
  isPrimarySubmit?: boolean;
  secondaryButtonAction?: MouseEventHandler<HTMLButtonElement>;
  secondaryButtonText?: string;
  tertiaryAction?: MouseEventHandler<HTMLAnchorElement>;
  tertiaryText?: string;
}

export const DataSourceLayoutButtonSection: React.FC<IDataSourceLayoutButtonSectionProps> = ({
  primaryButtonAction,
  primaryButtonText,
  isPrimaryButtonDisabled,
  isPrimaryButtonLoading,
  secondaryButtonAction,
  secondaryButtonText,
  tertiaryAction,
  tertiaryText,
  isPrimarySubmit = false,
}) => {
  return (
    <ButtonSectionWrapper>
      {secondaryButtonText != null && (
        <Button onClick={secondaryButtonAction} size="large" kind="secondary">
          {secondaryButtonText}
        </Button>
      )}
      {primaryButtonText != null && (
        <Button
          type={isPrimarySubmit ? "submit" : undefined}
          onClick={primaryButtonAction}
          size="large"
          kind="primary"
          disabled={isPrimaryButtonDisabled}
          loading={isPrimaryButtonLoading}
        >
          {primaryButtonText}
        </Button>
      )}
      {tertiaryText != null && <TertiaryLink onClick={tertiaryAction}>{tertiaryText}</TertiaryLink>}
    </ButtonSectionWrapper>
  );
};

const ButtonSectionWrapper = styled.div`
  gap: ${t.space[3].toString()};
  display: grid;
  grid-template-columns: auto auto 1fr;
  align-items: center;
`;

const TertiaryLink = styled.a`
  text-align: right;
`;
