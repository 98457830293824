import { useEffect } from "react";

import {
  ISegmentTrackEvent,
  ISegmentTrackPage,
  useAnalytics,
} from "~src/shared/thirdParties/segment";
import { IConnectDataSourceFlowSource } from "~src/shared/types";

export const useAccountingCSVUploadTracking = (
  source?: IConnectDataSourceFlowSource,
): ((
  accountingSourceToEventMap: {
    [key in IConnectDataSourceFlowSource]: ISegmentTrackEvent | null;
  },
) => void) => {
  const { trackEvent, trackPage } = useAnalytics();

  useEffect(() => {
    const impressionEvent =
      source != null
        ? CONNECT_ACCOUNTING_SOURCE_TO_CSV_UPLOADER_MODAL_IMPRESSION_EVENT[source]
        : null;
    if (impressionEvent != null) {
      trackPage(impressionEvent);
    }
  }, [source, trackPage]);

  return (accountingSourceToEventMap) => {
    const event = source != null ? accountingSourceToEventMap[source] : null;
    if (event != null) {
      trackEvent(event);
    }
  };
};

export const CONNECT_ACCOUNTING_SOURCE_TO_CSV_UPLOADER_MODAL_IMPRESSION_EVENT: {
  [key in IConnectDataSourceFlowSource]: ISegmentTrackPage | null;
} = {
  "notifications-badge": null,
  "signup-flow": null,
  "cash-performance-flow": null,
  "trade-orderbox-add-payout-flow": null,
  "being-reviewed-add-more-data": null,
  "settings-flow": ISegmentTrackPage.SettingsAddAccountingDataModalCSVUploaderModalImpression,
  "suggestion-flow": ISegmentTrackPage.SuggestionAddAccountingDataModalCSVUploaderModalImpression,
  "trade-required-action-flow":
    ISegmentTrackPage.TradeRequiredActionAddAccountingDataModalCSVUploaderModalImpression,
};

export const CONNECT_ACCOUNTING_SOURCE_TO_CSV_UPLOADER_MODAL_BACK_BUTTON_CLICKED_EVENT: {
  [key in IConnectDataSourceFlowSource]: ISegmentTrackEvent | null;
} = {
  "notifications-badge": null,
  "signup-flow": null,
  "cash-performance-flow": null,
  "trade-orderbox-add-payout-flow": null,
  "being-reviewed-add-more-data": null,
  "settings-flow":
    ISegmentTrackEvent.SettingsAddAccountingDataModalCSVUploaderModalBackButtonClicked,
  "suggestion-flow":
    ISegmentTrackEvent.SuggestionAddAccountingDataModalCSVUploaderModalBackButtonClicked,
  "trade-required-action-flow":
    ISegmentTrackEvent.TradeRequiredActionAddAccountingDataModalCSVUploaderModalBackButtonClicked,
};

export const CONNECT_ACCOUNTING_SOURCE_TO_CSV_UPLOADER_MODAL_CLOSED_OUT_EVENT: {
  [key in IConnectDataSourceFlowSource]: ISegmentTrackEvent | null;
} = {
  "notifications-badge": null,
  "signup-flow": null,
  "cash-performance-flow": null,
  "trade-orderbox-add-payout-flow": null,
  "being-reviewed-add-more-data": null,
  "settings-flow": ISegmentTrackEvent.SettingsAddAccountingDataModalCSVUploaderModalClosedOut,
  "suggestion-flow": ISegmentTrackEvent.SuggestionAddAccountingDataModalCSVUploaderModalClosedOut,
  "trade-required-action-flow":
    ISegmentTrackEvent.TradeRequiredActionAddAccountingDataModalCSVUploaderModalClosedOut,
};

export const CONNECT_ACCOUNTING_SOURCE_TO_CSV_UPLOADER_MODAL_FILE_UPLOADED_EVENT: {
  [key in IConnectDataSourceFlowSource]: ISegmentTrackEvent | null;
} = {
  "notifications-badge": null,
  "signup-flow": null,
  "cash-performance-flow": null,
  "trade-orderbox-add-payout-flow": null,
  "being-reviewed-add-more-data": null,
  "settings-flow": ISegmentTrackEvent.SettingsAddAccountingDataModalCSVUploaderModalFileUploaded,
  "suggestion-flow":
    ISegmentTrackEvent.SuggestionAddAccountingDataModalCSVUploaderModalFileUploaded,
  "trade-required-action-flow":
    ISegmentTrackEvent.TradeRequiredActionAddAccountingDataModalCSVUploaderModalFileUploaded,
};

export const CONNECT_ACCOUNTING_SOURCE_TO_CSV_UPLOADER_MODAL_UPLOAD_BUTTON_CLICKED_EVENT: {
  [key in IConnectDataSourceFlowSource]: ISegmentTrackEvent | null;
} = {
  "notifications-badge": null,
  "signup-flow": null,
  "cash-performance-flow": null,
  "trade-orderbox-add-payout-flow": null,
  "being-reviewed-add-more-data": null,
  "settings-flow":
    ISegmentTrackEvent.SettingsAddAccountingDataModalCSVUploaderModalUploadButtonClick,
  "suggestion-flow":
    ISegmentTrackEvent.SuggestionAddAccountingDataModalCSVUploaderModalUploadButtonClick,
  "trade-required-action-flow":
    ISegmentTrackEvent.TradeRequiredActionAddAccountingDataModalCSVUploaderModalUploadButtonClick,
};

export const CONNECT_ACCOUNTING_SOURCE_TO_CSV_UPLOADER_MODAL_TEMPLATE_LINK_CLICKED_EVENT: {
  [key in IConnectDataSourceFlowSource]: ISegmentTrackEvent | null;
} = {
  "notifications-badge": null,
  "signup-flow": null,
  "cash-performance-flow": null,
  "trade-orderbox-add-payout-flow": null,
  "being-reviewed-add-more-data": null,
  "settings-flow":
    ISegmentTrackEvent.SettingsAddAccountingDataModalCSVUploaderModalTemplateLinkClick,
  "suggestion-flow":
    ISegmentTrackEvent.SuggestionAddAccountingDataModalCSVUploaderModalTemplateLinkClick,
  "trade-required-action-flow":
    ISegmentTrackEvent.TradeRequiredActionAddAccountingDataModalCSVUploaderModalTemplateLinkClick,
};
