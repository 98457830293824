import { noop } from "lodash";
import React, { useEffect, useState } from "react";
import useScript from "react-script-hook";

import { Success } from "~src/designSystem/icons/Success";
import { ConsoleLog } from "~src/shared/helpers";
import { callRequest } from "~src/shared/requests/useRequest";
import { useStepper } from "~src/shared/stepper/stepperContext";
import { IConnectDataSourceFlowSource } from "~src/shared/types";
import { vendorRequests } from "~src/vendor/requests";

import { IAllowedRedirectPath } from "../../allowedRedirectPaths";
import { DataSourceInformationLayout } from "../../components/dataSourceInformationLayout";
import { useConnectDataSource } from "../../connectDataSource/hooks/useConnectDataSource";
import { SelectCountry } from "../SelectCountry";
import { useConnectBankState } from "../state";
import { onErrorCommon } from "./helpers";
import { IPlaid, IPlaidLinkHandler, PlaidEnabledCountryCode } from "./types";

declare global {
  interface Window {
    Plaid?: IPlaid;
  }
}

export interface IUsePlaidLinkOptions {
  onSuccess: () => Promise<void>;
  redirectPath: IAllowedRedirectPath;
  source: IConnectDataSourceFlowSource;
}

// The usePlaidLink hook in the official react-plaid-link SDK does not support
// asynchronous Link token creation. This hook is based on the official hook,
// but adds additional effect hooks to create and clear tokens. For a detailed
// explanation, see https://github.com/plaid/react-plaid-link/issues/128.
export const useConnectBank = (options: IUsePlaidLinkOptions): Pick<IPlaidLinkHandler, "open"> => {
  const { onSuccess, redirectPath, source } = options;
  const { addAndOpenStepperDialog, clearStepperStack } = useStepper();

  const [countryCode, setCountryCode] = useState<PlaidEnabledCountryCode | undefined>();

  const [token, setToken] = useState<string | undefined>();
  const [open, setOpen] = useState<IPlaidLinkHandler["open"]>(() => noop);

  const setProcessing = useConnectBankState((state) => state.setProcessing);

  // Load the Plaid Link initialization script asynchonously.
  const [loading, error] = useScript({
    src: "https://cdn.plaid.com/link/v2/stable/link-initialize.js",
    checkForExisting: true,
  });

  // Clear existing token whenever the countryCode changes.
  useEffect(() => setToken(undefined), [countryCode]);

  // Generate a new token whenever the existing token is cleared or the countryCode
  // is set.
  useEffect(() => {
    if (token !== undefined || countryCode === undefined) {
      return;
    }
    let stale = false;
    (async () => {
      const res = await callRequest(vendorRequests.createPlaidLinkToken({ countryCode }));
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (stale) {
        // There is a more recent effect running. No point in continuing in a stale effect
        return;
      }
      setToken(res.data?.token);
    })();
    return () => {
      stale = true;
    };
  }, [token, countryCode]);

  const onSetOpen = React.useCallback(() => {
    addAndOpenStepperDialog({
      component: (
        <SelectCountry
          onSelect={(s: PlaidEnabledCountryCode) => {
            setCountryCode(s);
          }}
          source={source}
        />
      ),
      config: {
        title: "Sync your bank account",
      },
    });
  }, [addAndOpenStepperDialog, source]);

  // Wait for the script to load before letting "open" launch the country selector.
  useEffect(() => {
    if (loading || error !== null) {
      return;
    }

    setOpen(() => () => {
      onSetOpen();
    });
  }, [loading, error, source, addAndOpenStepperDialog, onSetOpen]);

  // Connect Data Source flow starter
  const openConnectDataSource = useConnectDataSource({
    onSuccess,
    redirectPath,
    source,
  });

  // Initialize the Plaid handler once the Link token has been generated and
  // the Link initialization script has finished loading, and again whenever
  // the Link token is regenerated.
  useEffect(() => {
    if (token === undefined || window.Plaid === undefined) {
      return;
    }
    let stale = false;
    const handler = window.Plaid.create({
      token,
      onLoad: () => {
        ConsoleLog("Plaid loaded");
        handler.open();
      },
      onSuccess: async (publicToken, metadata) => {
        ConsoleLog("Plaid success", metadata);
        try {
          await callRequest(
            vendorRequests.linkPlaidItem({
              publicToken,
              institutionID: metadata.institution.institution_id,
              accountMasks: metadata.accounts
                .map((a) => a.mask)
                .filter((m): m is string => m != null),
            }),
          );
          await onSuccess();
          addAndOpenStepperDialog({
            component: (
              <DataSourceInformationLayout
                heading="Your bank has been added"
                description="Using multiple banks or banking in more than one country? Connect additional data sources to complete your financial profile."
                imageSrc={Success}
                primaryButtonText="Add another data source"
                primaryButtonAction={openConnectDataSource}
                secondaryButtonText="Done"
                secondaryButtonAction={clearStepperStack}
              />
            ),
          });
        } finally {
          stale = true;
          setProcessing(false);
          setCountryCode(undefined);
          handler.exit({ force: true }); // Close window.
          handler.destroy(); // Remove iframe.
        }
      },
      onEvent: (eventName, metadata) => ConsoleLog("Plaid event", eventName, metadata),
      onExit: async (onExitErr, metadata) => {
        setCountryCode(undefined);
        setProcessing(false);
        ConsoleLog("Plaid exit metadata", metadata);
        if (onExitErr === null) {
          return;
        }
        ConsoleLog("Plaid exit error", onExitErr);
        if (onExitErr.error_code === "INVALID_LINK_TOKEN") {
          // Generate a new token if the token has expired or the user has
          // attempted too many invalid logins.
          if (!stale) {
            setToken(undefined);
          }
          return;
        }
        onErrorCommon(onExitErr, metadata);
      },
    });
    return () => {
      stale = true;
      handler.exit({ force: true }); // Close window.
      handler.destroy(); // Remove iframe.
    };
    // NOTE: Only `token` needs to be in this array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { open };
};
