import { styled } from "~src/designSystem/theme";
import {
  backgroundColorStorybookArgs,
  backgroundColorVariants,
  borderRadiusStorybookArgs,
  borderRadiusVariants,
  contentColorStorybookArgs,
  contentColorVariants,
  gapStorybookArgs,
  gapVariants,
  marginStorybookArgs,
  marginVariants,
  paddingStorybookArgs,
  paddingVariants,
  shadowStorybookArgs,
  shadowVariants,
  sizeStorybookArgs,
  sizeVariants,
  typographyStorybookArgs,
  typographyVariants,
} from "~src/designSystem/theme/variants";
import { castToStitchesStoryComponent } from "~src/storybook/utils";

export const Flex = styled(
  "div",
  backgroundColorVariants,
  borderRadiusVariants,
  contentColorVariants,
  marginVariants,
  paddingVariants,
  shadowVariants,
  gapVariants,
  sizeVariants,
  typographyVariants,
  {
    display: "flex",

    variants: {
      direction: {
        "row": {
          flexDirection: "row",
        },
        "column": {
          flexDirection: "column",
        },
        "row-reverse": {
          flexDirection: "row-reverse",
        },
        "column-reverse": {
          flexDirection: "column-reverse",
        },
      },
      // Not once have we ever used wrap-reverse.
      wrap: {
        true: {
          flexWrap: "wrap",
        },
      },
      wrapReverse: {
        true: {
          flexWrap: "wrap-reverse",
        },
      },
      justify: {
        "start": {
          justifyContent: "flex-start",
        },
        "end": {
          justifyContent: "flex-end",
        },
        "center": {
          justifyContent: "center",
        },
        "space-between": {
          justifyContent: "space-between",
        },
        "space-around": {
          justifyContent: "space-around",
        },
        "space-evenly": {
          justifyContent: "space-evenly",
        },
      },
      align: {
        stretch: {
          alignItems: "stretch",
        },
        start: {
          alignItems: "flex-start",
        },
        end: {
          alignItems: "flex-end",
        },
        center: {
          alignItems: "center",
        },
        baseline: {
          alignItems: "baseline",
        },
      },
    },
  },
);

export const StorybookFlex = castToStitchesStoryComponent(Flex);

export const StorybookFlexArgs = {
  direction: {
    description: "flex-direction. The direction to flex in.",
    control: { type: "select" },
    table: {
      category: "Flex Variants",
      defaultValue: { summary: "row" },
    },
  },
  wrap: {
    description: "flex-wrap. Whether children can wrap into multiple lines.",
    control: { type: "checkbox" },
    table: {
      category: "Flex Variants",
      defaultValue: { summary: "false" },
    },
  },
  justify: {
    description:
      "justify-content. How to distribute space between/around children along the main axis.",
    control: { type: "select" },
    table: {
      category: "Flex Variants",
    },
  },
  align: {
    description: "align-items. How to align children along the secondary axis.",
    control: { type: "select" },
    table: {
      category: "Flex Variants",
      defaultValue: { summary: "stretch" },
    },
  },
  ...gapStorybookArgs,
  children: {
    description: "The items to flex.",
  },
  ...backgroundColorStorybookArgs,
  ...borderRadiusStorybookArgs,
  ...contentColorStorybookArgs,
  ...marginStorybookArgs,
  ...paddingStorybookArgs,
  ...shadowStorybookArgs,
  ...sizeStorybookArgs,
  ...typographyStorybookArgs,
};
