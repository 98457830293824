import styled from "@emotion/styled";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";

import { OldParagraphUseTextInstead } from "~src/designSystem/deprecated/Paragraph";
import { Success } from "~src/designSystem/icons/Success";
import { t } from "~src/designSystem/theme";
import { BILLING_MANAGERS } from "~src/shared/dataSources/billing/billingManagers";
import {
  CredentialField,
  SubdomainField,
} from "~src/shared/dataSources/components/CredentialField";
import { DataSourceLayout } from "~src/shared/dataSources/components/dataSourceLayout";
import { DataSourceLayoutButtonSection } from "~src/shared/dataSources/components/dataSourceLayout/DataSourceLayoutButtonSection";
import { useDataSourceCredentialStepTracking } from "~src/shared/dataSources/tracking/useDataSourceCredentialStepTracking";
import { WarningMessage } from "~src/shared/informationals/WarningMessage";
import { callRequest } from "~src/shared/requests/useRequest";
import { useStepper } from "~src/shared/stepper/stepperContext";
import { IConnectDataSourceFlowSource } from "~src/shared/types";
import { vendorRequests } from "~src/vendor/requests";

import { IAllowedRedirectPath } from "../../allowedRedirectPaths";
import { DataSourceInformationLayout } from "../../components/dataSourceInformationLayout";
import { useConnectDataSource } from "../../connectDataSource/hooks/useConnectDataSource";

interface IProps {
  source: IConnectDataSourceFlowSource;
  onSuccess: () => Promise<void>;
  /** The subdomain should be set when reconnecting a data source. */
  subdomain?: string;
  redirectPath: IAllowedRedirectPath;
}

export const ConnectRecurly: React.FC<IProps> = ({
  source,
  onSuccess,
  subdomain,
  redirectPath,
}) => {
  // To support "Add another" flow.
  const openConnectDataSource = useConnectDataSource({
    onSuccess,
    redirectPath,
    source,
  });

  const [submitError, setSubmitError] = useState<string>("");

  const { addAndOpenStepperDialog, clearStepperStack, removeTopOfStack } = useStepper();
  const { logOnGoBackEvent, logInstructionsButtonClickEvent } = useDataSourceCredentialStepTracking(
    {
      dataType: "billing_manager",
      source,
    },
  );
  const initialValues = {
    subdomain: subdomain ?? "",
    apiKey: "",
  };
  const validationSchema: yup.SchemaOf<typeof initialValues> = yup.object({
    subdomain: yup.string().trim().required("required"),
    apiKey: yup.string().trim().required("required"),
  });
  const form = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,

    onSubmit: async (values) => {
      const res = await callRequest(vendorRequests.linkRecurlyAccount(values), {
        handleRPCError: (err) => err.status === 400,
      });
      if (res.ok) {
        await onSuccess();
        addAndOpenStepperDialog({
          component: (
            <DataSourceInformationLayout
              heading="Recurly has been added"
              // TODO: PIPE-2525 image + description
              description=""
              primaryButtonText="Add another"
              primaryButtonAction={openConnectDataSource}
              imageSrc={Success}
              secondaryButtonText="Done"
              secondaryButtonAction={clearStepperStack}
            />
          ),
        });
      } else {
        setSubmitError(res.error?.errorMessage ?? "");
      }
    },
  });
  return (
    <DataSourceLayout
      dataSourceInfo={BILLING_MANAGERS.recurly}
      heading="Enter your credentials"
      description={
        <div>
          <OldParagraphUseTextInstead>
            Connecting allows us to automatically identify your revenue streams and sync them to
            your Pipe dashboard. At Pipe, we never share your data with outside third parties.
          </OldParagraphUseTextInstead>
          <OldParagraphUseTextInstead>
            Check out these{" "}
            <a
              onClick={logInstructionsButtonClickEvent}
              href="https://docs.recurly.com/docs/api-keys"
              target="_blank"
            >
              instructions
            </a>{" "}
            to learn more.
          </OldParagraphUseTextInstead>
        </div>
      }
    >
      <form
        onSubmit={form.handleSubmit}
        onChange={() => {
          setSubmitError("");
        }}
      >
        <FormFields>
          <SubdomainField fieldKey="subdomain" label="Subdomain" domain="recurly.com" form={form} />
          <CredentialField fieldKey="apiKey" type="password" label="API Key" form={form} />
        </FormFields>
        {submitError !== "" && (
          <WarningMessage margin="16px 0 0 0" type="error" message={submitError} />
        )}
        <ButtonSection>
          <DataSourceLayoutButtonSection
            secondaryButtonText="Back"
            secondaryButtonAction={(e) => {
              e.preventDefault();
              logOnGoBackEvent();
              removeTopOfStack();
            }}
            primaryButtonText="Continue"
            isPrimaryButtonDisabled={!(form.isValid && form.dirty) || form.isSubmitting}
            isPrimaryButtonLoading={form.isSubmitting}
            isPrimarySubmit
          />
        </ButtonSection>
      </form>
    </DataSourceLayout>
  );
};

const ButtonSection = styled.div`
  margin-top: ${t.space[8].toString()};
`;

const FormFields = styled.div`
  display: grid;
  grid-row-gap: ${t.space[5].toString()};
`;
