import styled from "@emotion/styled";

import { LoadingSpinner } from "~src/designSystem/loading/LoadingSpinner";
import { t } from "~src/designSystem/theme";

export const ConnectDataSourceSearching: React.FC = () => (
  <Wrapper>
    <LoadingSpinner size="50px" ringWidth="4px" />
  </Wrapper>
);

const Wrapper = styled.div`
  margin-top: ${t.space[16].toString()};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${t.space[5].toString()};
`;
