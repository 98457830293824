import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

import { PleaseGoUseFlexInstead } from "~src/designSystem/deprecated/Flexbox";
import { LoadingDots } from "~src/designSystem/loading/LoadingDots";
import { t } from "~src/designSystem/theme";

import { ChevronRight } from "./ChevronRight";
import { Default } from "./Default";
import { Syncing } from "./Syncing";
import { Warning } from "./Warning";

export type IConnectionBadgeVariant = "default" | "warning" | "syncing";

export type IConnectionBadgeProps = {
  loading?: boolean;
  label: string;
  variant?: IConnectionBadgeVariant;
  onClick?: () => void;
};

export const ConnectionBadge: React.FC<IConnectionBadgeProps> = ({
  loading,
  variant = "default",
  label,
  onClick,
}) => {
  const statusIcon = (() => {
    switch (variant) {
      case "warning": {
        return <Warning />;
      }
      case "syncing": {
        return <Syncing />;
      }
      default: {
        return <Default />;
      }
    }
  })();

  return (
    <Wrapper onClick={onClick}>
      {statusIcon}
      {loading === true ? (
        <IconWrapper>
          <LoadingDots size={20} />
        </IconWrapper>
      ) : (
        <Label variant={variant}>{label}</Label>
      )}
      <IconWrapper>
        <ChevronRight />
      </IconWrapper>
    </Wrapper>
  );
};

const Wrapper = styled(PleaseGoUseFlexInstead)<{ onClick?: () => void }>`
  background: ${(props) => props.theme.components.ConnectionBadge.Wrapper.background.default};
  border: 1px solid ${(props) => props.theme.components.ConnectionBadge.Wrapper.border};
  border-radius: 20px;

  flex-direction: row;
  align-items: center;
  flex-shrink: 1;
  gap: ${t.space[2].toString()};

  padding: ${t.c.spacing("0", "1", "0", "2")};
  height: 28px;
  width: fit-content;
  min-width: 168px;

  cursor: default;

  ${(props) =>
    props.onClick !== undefined &&
    css`
      :hover {
        background: ${props.theme.components.ConnectionBadge.Wrapper.background.hover};
      }
      cursor: pointer;
    `}
`;

const Label = styled.div<{ variant: IConnectionBadgeVariant }>`
  color: ${(props) =>
    props.variant === "warning"
      ? props.theme.components.ConnectionBadge.Label.color.warning
      : props.theme.components.ConnectionBadge.Label.color.default};
  font-size: ${(props) => props.theme.components.ConnectionBadge.Label.font.size};
  font-weight: ${(props) => props.theme.components.ConnectionBadge.Label.font.weight};
  line-height: ${(props) => props.theme.components.ConnectionBadge.Label.font.lineHeight};

  text-transform: capitalize;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const IconWrapper = styled.div`
  display: flex;
  margin-left: auto;
`;
