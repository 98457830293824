import create from "zustand";

type IState = {
  processing: boolean;
  setProcessing: (processing: boolean) => void;
};

export const useConnectBankState = create<IState>((set) => ({
  processing: false,
  setProcessing: (processing) => {
    set({ processing });
  },
}));
