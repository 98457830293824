import styled from "@emotion/styled";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";

import { OldParagraphUseTextInstead } from "~src/designSystem/deprecated/Paragraph";
import { Success } from "~src/designSystem/icons/Success";
import { t } from "~src/designSystem/theme";
import { BILLING_MANAGERS } from "~src/shared/dataSources/billing/billingManagers";
import { CredentialField } from "~src/shared/dataSources/components/CredentialField";
import { DataSourceLayout } from "~src/shared/dataSources/components/dataSourceLayout";
import { DataSourceLayoutButtonSection } from "~src/shared/dataSources/components/dataSourceLayout/DataSourceLayoutButtonSection";
import { useDataSourceCredentialStepTracking } from "~src/shared/dataSources/tracking/useDataSourceCredentialStepTracking";
import { WarningMessage } from "~src/shared/informationals/WarningMessage";
import { callRequest } from "~src/shared/requests/useRequest";
import { useStepper } from "~src/shared/stepper/stepperContext";
import { IConnectDataSourceFlowSource } from "~src/shared/types";
import { vendorRequests } from "~src/vendor/requests";

import { IAllowedRedirectPath } from "../../allowedRedirectPaths";
import { DataSourceInformationLayout } from "../../components/dataSourceInformationLayout";
import { useConnectDataSource } from "../../connectDataSource/hooks/useConnectDataSource";

interface IProps {
  source: IConnectDataSourceFlowSource;
  onSuccess: () => Promise<void>;
  redirectPath: IAllowedRedirectPath;
  appleVendorNumber?: string;
}

const APPLE_HELP_GUIDE_URL = "https://pipedocs.readme.io/docs/apple";

export const ConnectApple: React.FC<IProps> = ({
  source,
  onSuccess,
  redirectPath,
  appleVendorNumber,
}) => {
  // To support "Add another" flow.
  const openConnectDataSource = useConnectDataSource({
    onSuccess,
    redirectPath,
    source,
  });

  const [submitError, setSubmitError] = useState<string>("");

  const { addAndOpenStepperDialog, clearStepperStack, removeTopOfStack } = useStepper();
  const { logOnGoBackEvent, logInstructionsButtonClickEvent } = useDataSourceCredentialStepTracking(
    {
      dataType: "billing_manager",
      source,
    },
  );

  const initialValues = {
    reporterToken: "",
    vendorNumber: appleVendorNumber ?? "",
  };
  const validationSchema: yup.SchemaOf<typeof initialValues> = yup.object({
    reporterToken: yup.string().trim().required("required"),
    vendorNumber: yup
      .string()
      .matches(/^\d+$/, { message: "must only contain digits" })
      .required("required"),
  });
  const form = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async ({ vendorNumber, reporterToken }) => {
      const res = await callRequest(
        vendorRequests.linkAppleAccount({ vendorNumber, reporterToken }),
        {
          handleRPCError: (err) => err.errorMessage !== null,
        },
      );
      if (res.ok) {
        await onSuccess();
        addAndOpenStepperDialog({
          component: (
            <DataSourceInformationLayout
              heading="Apple has been added"
              // TODO: PIPE-2525 image + description
              description=""
              imageSrc={Success}
              primaryButtonText="Add another"
              primaryButtonAction={openConnectDataSource}
              secondaryButtonText="Done"
              secondaryButtonAction={clearStepperStack}
            />
          ),
        });
      } else {
        setSubmitError(res.error?.errorMessage ?? "");
      }
    },
  });
  return (
    <DataSourceLayout
      dataSourceInfo={BILLING_MANAGERS.apple}
      heading="Enter your credentials"
      description={
        <div>
          <OldParagraphUseTextInstead>
            Connecting allows us to automatically identify your revenue streams and sync them to
            your Pipe dashboard. At Pipe, we never share your data with outside third parties.
          </OldParagraphUseTextInstead>
          <OldParagraphUseTextInstead>
            Check out our{" "}
            <a
              onClick={logInstructionsButtonClickEvent}
              href={APPLE_HELP_GUIDE_URL}
              target="_blank"
            >
              documentation
            </a>{" "}
            to learn more.
          </OldParagraphUseTextInstead>
        </div>
      }
    >
      <form
        onSubmit={form.handleSubmit}
        onChange={() => {
          setSubmitError("");
        }}
      >
        <FormFields>
          <CredentialField
            fieldKey="reporterToken"
            type="text"
            label="Reporter Token"
            form={form}
          />
          <CredentialField fieldKey="vendorNumber" type="text" label="Vendor Number" form={form} />
        </FormFields>
        {submitError !== "" && (
          <WarningMessage margin="16px 0 0 0" type="error" message={submitError} />
        )}
        <ButtonSection>
          <DataSourceLayoutButtonSection
            secondaryButtonText="Back"
            secondaryButtonAction={(e) => {
              e.preventDefault();
              logOnGoBackEvent();
              removeTopOfStack();
            }}
            primaryButtonText="Continue"
            isPrimaryButtonDisabled={!(form.isValid && form.dirty) || form.isSubmitting}
            isPrimaryButtonLoading={form.isSubmitting}
            isPrimarySubmit
          />
        </ButtonSection>
      </form>
    </DataSourceLayout>
  );
};

const ButtonSection = styled.div`
  margin-top: ${t.space[8].toString()};
`;

const FormFields = styled.div`
  display: grid;
  grid-row-gap: ${t.space[5].toString()};
`;
