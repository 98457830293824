import React, { useCallback } from "react";

import { useUser } from "~src/shared/auth/useUser";
import { IAllowedRedirectPath } from "~src/shared/dataSources/allowedRedirectPaths";
import { IBillingManager } from "~src/shared/dataSources/billing/billingManagers";
import { ConnectDataSourceSearch } from "~src/shared/dataSources/components/search";
import { useStepper } from "~src/shared/stepper/stepperContext";
import { useAnalytics } from "~src/shared/thirdParties/segment";
import { IConnectDataSourceFlowSource } from "~src/shared/types";

import { CSVUploadExplainer } from "../../manual/CSVUploadExplainer";
import { CONNECT_BILLING_SOURCE_TO_EDUCATIONAL_MODAL_CLOSED_OUT_EVENT } from "../../tracking/useDataSourceUploadExplainerTracking";
import { CONNECT_BILLING_SOURCE_TO_CONNECTOR_MODAL_CLOSE } from "../../tracking/utils";
import { ConnectApple } from "../connectors/ConnectApple";
import { ConnectChargebee } from "../connectors/ConnectChargebee";
import { ConnectChargify } from "../connectors/ConnectChargify";
import { ConnectGoCardless } from "../connectors/ConnectGoCardless";
import { ConnectPayPal } from "../connectors/ConnectPayPal";
import { ConnectRecurly } from "../connectors/ConnectRecurly";
import { ConnectStripe } from "../connectors/ConnectStripe";

interface IUseConnectBillingArgs {
  /** Invoked after a user successfully submits a credential form. */
  onSuccess: () => Promise<void>;
  /** Invoked after a user successfully completes an OAuth flow. */
  redirectPath: IAllowedRedirectPath;
  source: IConnectDataSourceFlowSource;
}

export interface IUseConnectBillingHandler {
  open: () => void;
}

export const useConnectBilling = ({
  onSuccess,
  redirectPath,
  source,
}: IUseConnectBillingArgs): IUseConnectBillingHandler => {
  const { addAndOpenStepperDialog, clearStepperStack } = useStepper();
  const { trackEvent } = useAnalytics();
  const { country } = useUser().vendor;

  const onSelectIntegration = useCallback(
    (billingManager: IBillingManager, query: string) => {
      let nextStepComponent;
      let onCloseTrackingEvent = CONNECT_BILLING_SOURCE_TO_CONNECTOR_MODAL_CLOSE[source];
      switch (billingManager.key) {
        case "stripe":
          nextStepComponent = <ConnectStripe redirectPath={redirectPath} />;
          break;
        case "chargebee":
          nextStepComponent = (
            <ConnectChargebee
              redirectPath={redirectPath}
              source={source}
              onSuccess={async () => {
                await onSuccess();
              }}
            />
          );
          break;
        case "gocardless":
          nextStepComponent = <ConnectGoCardless redirectPath={redirectPath} />;
          break;
        case "recurly":
          nextStepComponent = (
            <ConnectRecurly
              source={source}
              redirectPath={redirectPath}
              onSuccess={async () => {
                await onSuccess();
              }}
            />
          );
          break;
        case "chargify":
          nextStepComponent = (
            <ConnectChargify
              redirectPath={redirectPath}
              source={source}
              onSuccess={async () => {
                await onSuccess();
              }}
            />
          );
          break;
        case "apple":
          nextStepComponent = (
            <ConnectApple
              source={source}
              redirectPath={redirectPath}
              onSuccess={async () => {
                await onSuccess();
              }}
            />
          );
          break;
        case "paypal":
          nextStepComponent = <ConnectPayPal redirectPath={redirectPath} />;
          break;
        case "csv":
          nextStepComponent = (
            <CSVUploadExplainer source={source} label={query} onSuccess={onSuccess} />
          );
          onCloseTrackingEvent =
            CONNECT_BILLING_SOURCE_TO_EDUCATIONAL_MODAL_CLOSED_OUT_EVENT[source];
          break;
        default:
          return;
      }
      addAndOpenStepperDialog({
        component: nextStepComponent,
        config: {
          title: "Sync your revenue",
          onCloseOverride: () => {
            if (onCloseTrackingEvent != null) {
              trackEvent(onCloseTrackingEvent);
            }
            clearStepperStack();
          },
        },
      });
    },
    [source, addAndOpenStepperDialog, redirectPath, onSuccess, clearStepperStack, trackEvent],
  );

  const open = useCallback(() => {
    addAndOpenStepperDialog({
      component: (
        <ConnectDataSourceSearch
          type="billing_manager"
          onSelect={onSelectIntegration}
          source={source}
          country={country ?? undefined}
        />
      ),
      config: {
        title: "Sync your revenue",
      },
    });
  }, [source, addAndOpenStepperDialog, onSelectIntegration, country]);

  return { open };
};
