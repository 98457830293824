import { useMaybeUser } from "~src/shared/auth/useUser";
import { IGetUserProfileData } from "~src/shared/requests/authedRequests";

/**
 * List of all feature flags v2. The source of truth for all feature flags is LaunchDarkly.
 * If the feature flag is unused, please delete it from this list.
 * Feature flags are managed in LaunchDarkly and evaluated based on various properties
 * and targeting rules.
 *
 * When adding a new feature flag, first create it in LaunchDarkly and then add it to the
 * list below.
 *
 * If the feature flag is unused, check if the flag's targeting is off in LaunchDarkly
 * and it hasn't been evaluated in the last 30 days. If the flag is no longer being used,
 * archive it in LaunchDarkly and then please delete it from this list.
 */
export enum IFeatureFlagName {
  APPLY_LIVE_RATINGS = "apply-live-ratings",
  CONNECT_API = "connect_api",
  FRACTIONAL_SELLS = "fractional_sells",
  HELP_WIDGET = "help-widget",
  HELP_WIDGET_INTERCOM = "help-widget-intercom",
  PIPE_X = "pipe_x",
}

export type IFeatureFlagChecker = (flag: IFeatureFlagName) => boolean;

/**
 * useFeatureFlag is a hook that determines if a feature flag is enabled or not.
 *
 * @param flag: Name of flag from IFeatureFlagName
 * @returns boolean
 */
export const useFeatureFlag: IFeatureFlagChecker = (flag) => {
  return useFeatureFlagChecker()(flag);
};

/**
 * useFeatureFlagChecker is a hook that returns a function that checks
 * whether a feature flag is active for the current user or not.
 *
 * Use this when you need to check for feature flags in a location where you
 * cannot use a hook.
 */
export const useFeatureFlagChecker = (): IFeatureFlagChecker => {
  const user = useMaybeUser();

  return (flag: IFeatureFlagName) => isFeatureFlagEnabled(user, flag);
};

const isFeatureFlagEnabled = (
  user: IGetUserProfileData | null,
  flag: IFeatureFlagName,
): boolean => {
  const isEnabled = user?.flagz?.[flag.toLowerCase()];

  return isEnabled === true;
};
