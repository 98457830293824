/** @jsxImportSource @emotion/react */
import { IconName } from "@blueprintjs/icons";
import { css } from "@emotion/react";
import React from "react";

import { BlueprintIcon } from "~src/designSystem/deprecated/BlueprintIcon";

import { PleaseGoUseFlexInstead } from "../../deprecated/Flexbox";

export type IIconTextProps = {
  className?: string;
  iconName: IconName;
  /** this should be constrained */
  iconSize: number;
  /** inherit colors from text by default */
  iconColor?: string;
  gap?: number;
  children: React.ReactNode;
  iconLocation?: "left" | "right";
  onClick?: () => void;
};

/**
 * This component combines a BlueprintJS icon with some text, vertically centering the
 * BlueprintJS icon with the text.
 */
export const IconText: React.FC<IIconTextProps> = ({
  className,
  iconName,
  iconSize,
  iconColor,
  gap = 6,
  children,
  iconLocation = "left",
}) => {
  const elems = [
    <BlueprintIcon
      key="1"
      css={css`
        display: flex;
        align-self: center;
      `}
      color={iconColor}
      size={iconSize}
      icon={iconName}
    />,
    children,
  ];

  return (
    <PleaseGoUseFlexInstead row className={className} gap={`${gap}px`}>
      {iconLocation === "left" ? elems : elems.reverse()}
    </PleaseGoUseFlexInstead>
  );
};
