import styled from "@emotion/styled";

import { t } from "~src/designSystem/theme";

export const V2Layout = styled.div`
  display: flex;

  height: 100vh;

  background-color: ${t.colors.surfaceBackground.toString()};
  transition: background-color 0.1s ease;
`;
