import { useRouter } from "next/router";
import React from "react";

import { useConnectAccounting } from "~src/shared/dataSources/accounting/hooks/useConnectAccounting";
import { useConnectBank } from "~src/shared/dataSources/bank/hooks/useConnectBank";
import { useConnectBilling } from "~src/shared/dataSources/billing/hooks/useConnectBilling";
import { useFetchDataSourceRequirements } from "~src/shared/dataSourcesRequirements/hooks/useFetchDataSourceRequirements";
import { ErrorBoundary } from "~src/shared/errors/components/ErrorBoundary";
import { ISegmentTrackEvent, useAnalytics } from "~src/shared/thirdParties/segment";

import { NotificationsBadgeError, NotificationsBadgePresentation } from "./index.presentation";

// TODO(md): Write an actually useful error boundary abstraction. This is part of the
// frontend error handling project.

// This is a "notifications badge" that purely serves as a frontend for the data sources
// requirements. It does not implement arbitrary notifications; rather, we only show a
// predefined set of notifications derived from the state of a vendor's data sources, as
// determined by our requirements criteria.
export const NotificationsBadge: React.FC = () => (
  <ErrorBoundary fallback={<NotificationsBadgeError />}>
    <NotificationsBadgeBody />
  </ErrorBoundary>
);

const NotificationsBadgeBody: React.FC = () => {
  const router = useRouter();
  const { trackEvent } = useAnalytics();

  const { data: requirements, refetch, loading, error } = useFetchDataSourceRequirements();

  // If we have an error; toss it to the error boundary and unmount this component.
  if (error) {
    throw error;
  }

  // Setup onClick actions for "initial" and "additional" data source notifications.
  // For "relink" notifications, user is redirected to /settings/data-sources.
  const handleDataSourceLinkSuccess = React.useCallback(async (): Promise<void> => {
    await refetch();
  }, [refetch]);

  // Connect Plaid flow starter
  const { open: openPlaid } = useConnectBank({
    onSuccess: handleDataSourceLinkSuccess,
    redirectPath: "/settings/data-sources",
    source: "notifications-badge",
  });

  // Connect Billing flow starter
  const { open: openBilling } = useConnectBilling({
    onSuccess: handleDataSourceLinkSuccess,
    redirectPath: "/settings/data-sources",
    source: "notifications-badge",
  });

  // Connect Accounting flow starter
  const { open: openAccounting } = useConnectAccounting({
    onSuccess: handleDataSourceLinkSuccess,
    redirectPath: "/settings/data-sources",
    source: "notifications-badge",
  });

  return (
    <NotificationsBadgePresentation
      loading={loading}
      requirements={requirements}
      onRelinkNotificationClick={() => router.push("/settings/data-sources")}
      onConnectAccountingClick={openAccounting}
      onConnectBillingClick={openBilling}
      onConnectBankClick={openPlaid}
      onOpen={() => trackEvent(ISegmentTrackEvent.NotificationsBadgeClicked)}
    />
  );
};
