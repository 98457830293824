import { useEffect } from "react";

import {
  ISegmentTrackEvent,
  ISegmentTrackPage,
  useAnalytics,
} from "~src/shared/thirdParties/segment";
import { IConnectDataSourceFlowSource } from "~src/shared/types";

export const useDataSourceUploadExplainerTracking = (
  source?: IConnectDataSourceFlowSource,
): ((
  billingSourceToEventMap: {
    [key in IConnectDataSourceFlowSource]: ISegmentTrackEvent | null;
  },
) => void) => {
  const { trackEvent, trackPage } = useAnalytics();

  useEffect(() => {
    const impressionEvent =
      source != null ? CONNECT_BILLING_SOURCE_TO_EDUCATIONAL_MODAL_IMPRESSION_EVENT[source] : null;
    if (impressionEvent != null) {
      trackPage(impressionEvent);
    }
  }, [source, trackPage]);

  return (billingSourceToEventMap) => {
    const event = source != null ? billingSourceToEventMap[source] : null;
    if (event != null) {
      trackEvent(event);
    }
  };
};

export const CONNECT_BILLING_SOURCE_TO_EDUCATIONAL_MODAL_IMPRESSION_EVENT: {
  [key in IConnectDataSourceFlowSource]: ISegmentTrackPage | null;
} = {
  "notifications-badge": null,
  "signup-flow": null,
  "trade-orderbox-add-payout-flow": null,
  "cash-performance-flow": null,
  "being-reviewed-add-more-data": null,
  "settings-flow":
    ISegmentTrackPage.SettingsBillingManagerPageBillingManagerModalCSVUploadEducationalModalImpression,
  "suggestion-flow":
    ISegmentTrackPage.SuggestionBillingManagerPageBillingManagerModalCSVUploadEducationalModalImpression,
  "trade-required-action-flow":
    ISegmentTrackPage.TradeRequiredActionBillingManagerPageBillingManagerModalCSVUploadEducationalModalImpression,
};

export const CONNECT_BILLING_SOURCE_TO_EDUCATIONAL_MODAL_BACK_BUTTON_CLICKED_EVENT: {
  [key in IConnectDataSourceFlowSource]: ISegmentTrackEvent | null;
} = {
  "notifications-badge": null,
  "signup-flow": null,
  "trade-orderbox-add-payout-flow": null,
  "cash-performance-flow": null,
  "being-reviewed-add-more-data": null,
  "settings-flow":
    ISegmentTrackEvent.SettingsBillingManagerPageBillingManagerModalCSVUploadEducationalModalBackButtonClicked,
  "suggestion-flow":
    ISegmentTrackEvent.SuggestionBillingManagerPageBillingManagerModalCSVUploadEducationalModalBackButtonClicked,
  "trade-required-action-flow":
    ISegmentTrackEvent.TradeRequiredActionBillingManagerPageBillingManagerModalCSVUploadEducationalModalBackButtonClicked,
};

export const CONNECT_BILLING_SOURCE_TO_EDUCATIONAL_MODAL_CLOSED_OUT_EVENT: {
  [key in IConnectDataSourceFlowSource]: ISegmentTrackEvent | null;
} = {
  "notifications-badge": null,
  "signup-flow": null,
  "trade-orderbox-add-payout-flow": null,
  "cash-performance-flow": null,
  "being-reviewed-add-more-data": null,
  "settings-flow":
    ISegmentTrackEvent.SettingsBillingManagerPageBillingManagerModalCSVUploadEducationalModalClosedOut,
  "suggestion-flow":
    ISegmentTrackEvent.SuggestionBillingManagerPageBillingManagerModalCSVUploadEducationalModalClosedOut,
  "trade-required-action-flow":
    ISegmentTrackEvent.TradeRequiredActionBillingManagerPageBillingManagerModalCSVUploadEducationalModalClosedOut,
};

export const CONNECT_BILLING_SOURCE_TO_EDUCATIONAL_MODAL_CONTACT_SUPPORT_LINK_CLICKED_EVENT: {
  [key in IConnectDataSourceFlowSource]: ISegmentTrackEvent | null;
} = {
  "notifications-badge": null,
  "signup-flow": null,
  "trade-orderbox-add-payout-flow": null,
  "cash-performance-flow": null,
  "being-reviewed-add-more-data": null,
  "settings-flow":
    ISegmentTrackEvent.SettingsBillingManagerPageBillingManagerModalCSVUploadEducationalModalContactSupportLinkClick,
  "suggestion-flow":
    ISegmentTrackEvent.SuggestionBillingManagerPageBillingManagerModalCSVUploadEducationalModalContactSupportLinkClick,
  "trade-required-action-flow":
    ISegmentTrackEvent.TradeRequiredActionBillingManagerPageBillingManagerModalCSVUploadEducationalModalContactSupportLinkClick,
};

export const CONNECT_BILLING_SOURCE_TO_EDUCATIONAL_MODAL_CONTINUE_BUTTON_CLICKED_EVENT: {
  [key in IConnectDataSourceFlowSource]: ISegmentTrackEvent | null;
} = {
  "notifications-badge": null,
  "signup-flow": null,
  "trade-orderbox-add-payout-flow": null,
  "cash-performance-flow": null,
  "being-reviewed-add-more-data": null,
  "settings-flow":
    ISegmentTrackEvent.SettingsBillingManagerPageBillingManagerModalCSVUploadEducationalModalContinueButtonClick,
  "suggestion-flow":
    ISegmentTrackEvent.SuggestionBillingManagerPageBillingManagerModalCSVUploadEducationalModalContinueButtonClick,
  "trade-required-action-flow":
    ISegmentTrackEvent.TradeRequiredActionBillingManagerPageBillingManagerModalCSVUploadEducationalModalContinueButtonClick,
};

export const CONNECT_BILLING_SOURCE_TO_EDUCATIONAL_MODAL_DOCS_LINK_CLICKED_EVENT: {
  [key in IConnectDataSourceFlowSource]: ISegmentTrackEvent | null;
} = {
  "notifications-badge": null,
  "signup-flow": null,
  "trade-orderbox-add-payout-flow": null,
  "cash-performance-flow": null,
  "being-reviewed-add-more-data": null,
  "settings-flow":
    ISegmentTrackEvent.SettingsBillingManagerPageBillingManagerModalCSVUploadEducationalModalDocsLinkClick,
  "suggestion-flow":
    ISegmentTrackEvent.SuggestionBillingManagerPageBillingManagerModalCSVUploadEducationalModalDocsLinkClick,
  "trade-required-action-flow":
    ISegmentTrackEvent.TradeRequiredActionBillingManagerPageBillingManagerModalCSVUploadEducationalModalDocsLinkClick,
};

export const CONNECT_BILLING_SOURCE_TO_EDUCATIONAL_MODAL_TEMPLATE_LINK_CLICKED_EVENT: {
  [key in IConnectDataSourceFlowSource]: ISegmentTrackEvent | null;
} = {
  "notifications-badge": null,
  "signup-flow": null,
  "trade-orderbox-add-payout-flow": null,
  "cash-performance-flow": null,
  "being-reviewed-add-more-data": null,
  "settings-flow":
    ISegmentTrackEvent.SettingsBillingManagerPageBillingManagerModalCSVUploadEducationalModalTemplateLinkClick,
  "suggestion-flow":
    ISegmentTrackEvent.SuggestionBillingManagerPageBillingManagerModalCSVUploadEducationalModalTemplateLinkClick,
  "trade-required-action-flow":
    ISegmentTrackEvent.TradeRequiredActionBillingManagerPageBillingManagerModalCSVUploadEducationalModalTemplateLinkClick,
};
